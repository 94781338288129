const storeLocatorHelpers = (function () {
    return {
        /**
         * Returns the base URL of the current page
         * @returns {string} - the base URL of the current page
         */
        getBaseURL: function () {
            return location.protocol + "//" + location.hostname + (location.port && ":" + location.port) + ((document.URL.indexOf("mdev") >= 0) ? "/" + location.pathname.split("/")[1] : "");
        },
        /**
         * Gets the value of a URL parameter
         * @param {string} name - the name of the URL parameter
         * @returns {string} - the value of the URL parameter
         */
        getURLParameter: function (name) {
            /* eslint-disable */
            var urlParameter = (RegExp(name + "=" + "(.+?)(&|$)").exec(location.search)||[, null])[1]; // NOSONAR
            /* eslint-enable */
            return (urlParameter != null) ? decodeURIComponent(urlParameter) : null;
        },
        /**
         * Returns the value of a URL parameter
         * @param {string} name - the name of the parameter
         * @param {string} url - the URL to search
         * @returns {string} the value of the parameter or null if not found
         */
        getURLParameterByName: function (name, url) {
            if (!url) {
                url = window.location.href;
            }
            name = name.replace(/[\[\]]/g, "\\$&"); // eslint-disable-line
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        /**
        * Checks if a variable is defined
        * @param {string} variableStr - the name of the variable to check
        * @param {Object} scope - the scope of the variable to check (optional)
        * @returns {boolean} - true if the variable is defined, false otherwise
        */
        isVariableDefined: function (variableStr, scope) {
            try {
                if (typeof scope !== "undefined") {
                    return scope.hasOwnProperty(variableStr); // eslint-disable-line
                } else {
                    return (typeof(eval(variableStr)) !== "undefined");
                }
            }
            catch (err) {
                return false;
            }
        },
        /**
        * Formats a given date object to 12-hour time format
        * EXAMPLE USAGE
        * formatTimeAMPM(new Date('March 30, 2014 16:14:00'));
        * @param {Date} date - the date object to format
        * @returns {string} - the formatted date string in 12-hour time format
        */
        formatTimeAMPM: function (date) {
            var hrs = date.getHours();
            var mins =  date.getMinutes();
            var ampm = " AM";

            // Convert 24hr format to 12hr format
            if (hrs >= 12) ampm = " PM";
            if (hrs > 12) hrs = hrs - 12;
            if (hrs === 0) hrs = 12;

            // Ensure minutes are always 2-digits
            if (mins < 10) mins = "0" + mins.toString();

            return hrs + ":" + mins + ampm;
        },
        /**
        * Creates an anchor element with the given href
        * @param {string} href - the href of the anchor element
        * @returns {HTMLAnchorElement} - the created anchor element
        */
        getLocation: function (href) {
            var link = document.createElement("a");
            link.href = href;
            return link;
        },
        /**
         * Formats a distance to a readable format
         */
        formatDistance: function (distance) {
            // cast the distance to a float
            distance = distance ? parseFloat(distance) : 0;

            // TODO: localize this better for languages / other countries
            var units = "km";

            var precision = 0;
            if (distance < 20) {
                // If the distance is < 20 units, display 1 decimal precision
                precision = 1;
            }

            return (distance.toFixed(precision) * 1) + units;
        },
        /**
         * Formats the location data for display
         * @param {Object} locationData - the location data to format
         * @param {Object} usersPosition - the user's current position
         * @param {Object} BrowserDetect - the browser detect object
         * @returns {Object} the formatted location data
         */
        formatLocationData: function (locationData, usersPosition, BrowserDetect) { // NOSONAR
            // Format Distance from User's Location
            locationData.formatted_distance_from_start_location = this.formatDistance(locationData.distance_from_start_location);

            // Format the Address
            if (locationData.address.length == 0) {
                locationData.formatted_address = locationData.city;
            } else if (locationData.city.length == 0) {
                locationData.formatted_address = locationData.address;
            } else {
                locationData.formatted_address = locationData.address+", "+locationData.city;
            }

            // Format Directions Link
            var daddr = locationData.latitude+","+locationData.longitude;
            var saddr = (usersPosition) ? ""+usersPosition.lat()+","+usersPosition.lng() : "";
            if (BrowserDetect.os == "iOS" && BrowserDetect.version >= 6) {
                locationData.directions_link = "http://maps.apple.com/?daddr="+daddr+"&saddr="+saddr;
            } else {
                locationData.directions_link = "http://maps.google.com/?daddr="+daddr+"&saddr="+saddr;
            }

            locationData.storedTires = ($("#hasStoredTires").val() == "true") ? true : false;
            return locationData;
        },
        /**
        * Calculates the approximate radius in kilometers for the current map zoom level
        * @param {google.maps.Map} map - the Google Maps map object
        * @returns {number} - approximate radius in kilometers
        */
        getMapRadius: function (map, isMobile) {
            var latitude = map.getCenter().lat();
            var zoom = map.getZoom();
            var mapViewHeight = $("#iam-sl-map-view").height();

            // in first load, the map is not rendered yet, so there is no height value
            if (mapViewHeight === 0) {
                mapViewHeight = isMobile ? 300 : 820; // map height value from PSD
            }

            // Earth radius is approximatelly 6,378,137 meters
            // Google Maps tiles are 256 device-independent pixels
            // Figure 156543.03392 corresponds to 2 * π * 6378137 / 256
            var groundResolution = 156543.03392 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom);

            var mapInMeters = groundResolution * (mapViewHeight * 1.6);

            // return approximate radius in kilometers for the current map zoom level
            return mapInMeters / 2 / 1000;
        },
        /**
        * Checks if a given location is within the bounds of a map
        * @param {Object} locationData - the location data containing latitude and longitude
        * @param {google.maps.Map} mapObject - the map object
        * @returns {boolean} true if the location is within the map bounds, false otherwise
        */
        isLocationWithinMapBounds: function (locationData, mapObject) {
            var locationPosition = new google.maps.LatLng(locationData.latitude, locationData.longitude); // eslint-disable-line
            var bounds = mapObject.getBounds();

            return bounds && bounds.contains(locationPosition);
        },
        /**
        * Adds a modal loader to the given element
        * @param {Element} elem - The element to which the modal loader should be added
        */
        addModalLoader: function (elem) {
            var wrapper = $(elem).closest(".button-wrapper");
            var modalLoader = document.createElement("div");

            modalLoader.setAttribute("id", "modalLoader");
            $(modalLoader).addClass("loader");

            $(wrapper).after(modalLoader);
            wrapper.hide();

            $("#modalLoader").fadeIn();
        },
        /**
         * Handles navigation to the next step
         * @param {string} $step - current step of trailer modal
         */
        nextStepActive: function ($step) {
            var $nextStep = $step.next(".trailer-modal-step");
            $step.removeClass("current");
            $(".second-title").removeClass("active");
            $nextStep.addClass("current");
            $nextStep.find(".second-title").addClass("active");

            // remove disabled class from active title
            $(".modal-header h3").removeClass("active-title");
            var toggleInfo = $(".trailer-modal-step.current").data("step");
            $(".modal-header h3." + toggleInfo + "").removeClass("disabled").addClass("active-title");
        },
        /**
         * Disables unactive step titles
         * @param {string} $this - title of current step
         */
        editSelectedOptions: function ($this) {
            $(".second-title").removeClass("active");
            $(".step-title").removeClass("active-title");
            $this.addClass("active-title");
            var trailerTitle = $(".secondary-modal-title");
            trailerTitle.not(".active-title").addClass("disabled");

            // if last step is active, remove disabled class from previous steps
            if ($this.hasClass("trailerLength")) {
                trailerTitle.removeClass("disabled");
            }
        },
        /**
         * Add a breadcrumb filter
         * @param {String} filterId
         * @param {String} filterText
         */
        addBreadcrumbFilter: function (filterId, filterText) {
            // create element with filter ID in breadcrumb
            $(".no-filters").hide();
            var breadcrumbElement = "<div class=\"button small breadcrumb-filter\" data-filterid=\"" + filterId + "\">" + filterText + " <span class=\"icon-cancel clear-breadcrumb-filter\"></span></div>";
            $(".breadcrumbs-filters").append(breadcrumbElement);
        },
        /**
         * Show an error message to the user
         * @param {string} errorMessage - Error message to be displayed
         */
        showErrorMessage: function (errorMessage) {
            var $errorContainer = $("#iam-sl-error-container");

            // Set a generic error as a fallback
            if (typeof errorMessage != "string" || errorMessage.length == 0) {
                errorMessage = "Sorry, an error occurred. Please try again.";
            }

            // Populate the error message
            $errorContainer.find(".error-message").text(errorMessage);

            // Show the Error Message
            $errorContainer.fadeIn();
        },
        /**
        * Hides the error message container by fading it out
        * This function targets the HTML element with the ID 'iam-sl-error-container'
        * and applies a fade-out animation to it
        */
        hideErrorMessage: function () {
            var $errorContainer = $("#iam-sl-error-container");
            $errorContainer.fadeOut();
        },
        /**
        * Checks if an object is empty (has no enumerable properties).
        * @param {Object} obj - The object to check.
        * @returns {boolean} - Returns true if the object is empty, otherwise false.
        */
        isObjectEmpty: function (obj) {
            return obj && Object.keys(obj).length === 0;
        },
        /**
         * Stop all AppointmentPlus ajax requests, or stop all kinds of requests on the page load
         * @param {boolean} stopAll - whether to stop all requests or just API calls and timeouts
         * @param {Array} apiCalls - array of API calls
         * @param {Object} timeoutsRunning - object of timeouts
         */
        stopRequests: function (stopAll, apiCalls, timeoutsRunning) {
            apiCalls.map(function (call) {
                call.abort();
            });

            Object.keys(timeoutsRunning).map(function (time) { // NOSONAR
                clearTimeout(time);
            });

            apiCalls = []; // NOSONAR
            timeoutsRunning = {};// NOSONAR

            if (stopAll) {
                window.stop();
            }
        },
        /**
         * @function
         * @description Initializes PowerReviews and renders the Category Snippet component for each store listed on the page
         */
        powerReviews: function () {
            window.pwr = window.pwr || function () {
                (pwr.q = pwr.q || []).push(arguments);
            };

            var stores = [];
            var storeLocator = $(".store-locator-page");
            var apiKey = storeLocator.data("api-key");
            var locale = storeLocator.data("locale");
            var merchantId = storeLocator.data("merchant-id");
            var merchantGroupId = storeLocator.data("merchant-group-id");
            var styleSheet = storeLocator.data("style");

            $(".powerreviews-container").each(function (index) {
                var storeId = $(this).data("location_id");
                $(this).append("<div id=\"category-snippet-" + index + "\"></div>");

                var store = {
                    ENABLE_CLIENT_SIDE_STRUCTURED_DATA: false,
                    api_key: apiKey,
                    locale: locale,
                    merchant_group_id: merchantGroupId,
                    merchant_id: merchantId,
                    page_id: storeId,
                    style_sheet: styleSheet,
                    components: {
                        CategorySnippet: "category-snippet-" + index,
                    }
                };

                stores.push(store);
            });

            pwr("render", stores);
        },
        /**
        * Refreshes the summary section of the page
        */
        refreshSummary: function () {
            var $summary = $("#secondary.summary");
            $summary.fadeOut();
    
            $summary.load(Urls.summaryRefreshURL, function () {
                $summary.fadeIn("fast");
            });
        },
        /**
         * Safely executes a callback function that requires Google Maps.
         * This function checks if the specified Google Maps API libraries are loaded and available.
         * If they are, the callback is executed immediately.
         * If not, the callback is executed once the 'googleMapSrcLoaded' event is triggered for all libraries.
         * @param {string|string[]} googleMapLibType - The type(s) of Google Maps API library to check for (e.g., 'places', 'geometry', etc.).
         * This should correspond to the property name(s) under google.maps that you want to ensure are available before executing the callback.
         * @param {Function} callback - The function to execute once Google Maps is available.
         */
        executeWithGoogleMaps: function (googleMapLibType, callback) {
            var checkLibraryAvailability = (libType) => {
                return typeof google !== "undefined" && google.maps[libType];
            };

            var libraryTypes = Array.isArray(googleMapLibType) ? googleMapLibType : [googleMapLibType];
            var allLibrariesAvailable = libraryTypes.every(checkLibraryAvailability);

            if (allLibrariesAvailable) {
                callback();
                return;
            }
        
            var listener = () => {
                if (libraryTypes.every(checkLibraryAvailability)) {
                    callback();

                    libraryTypes.forEach(libType => {
                        document.removeEventListener("googleMaps" + libType + "Loaded", listener);
                    });
                }
            };
        
            libraryTypes.forEach(libType => {
                document.addEventListener("googleMaps" + libType + "Loaded", listener);
            });
        },
        /**
         * Retrieves the available Google Maps library types.
         * This function returns an object containing the types of Google Maps libraries
         * that can be used, such as 'places' and 'geometry'.
         *
         * @returns {Object} An object with keys representing the library types and values as their string identifiers.
         *                   Example: { PLACES: "places", GEOMETRY: "geometry" }
         */
        getGoogleMapLibTypes: function () {
            return {
                PLACES   : "places",
                GEOMETRY : "geometry",
                MARKER   : "marker"
            };
        },
        /**
         * Sets the icon for a Google Maps AdvancedMarkerElement.
         * This function assigns an icon to the content property of the marker element.
         *
         * @param {google.maps.marker.AdvancedMarkerElement} markerElement - The marker element to which the icon will be set.
         * @param {string} iconUrl - The URL of the icon image to be used.
         */
        setIcon: function (markerElement, iconUrl) {
            var icon = this.getIcon(iconUrl);
            markerElement.content = icon;
        },
        /**
         * Creates an image element for the icon.
         * This function creates an HTML image element with the specified icon URL.
         *
         * @param {string} iconUrl - The URL of the icon image.
         * @returns {HTMLImageElement} - The created image element with the specified icon URL.
         */
        getIcon: function (iconUrl) {
            var icon = document.createElement("img");
            icon.src = iconUrl;
            return icon;
        },
        /**
         * Sets the visibility of a Google Maps AdvancedMarkerElement on the map.
         * This function sets the map property of the marker element to show or hide it on the map.
         *
         * @param {google.maps.marker.AdvancedMarkerElement} markerElement - The marker element whose visibility is to be set.
         * @param {boolean} visible - A boolean indicating whether the marker should be visible.
         * @param {google.maps.Map} mapObject - The map object on which the marker is displayed.
         */
        setVisible: function (markerElement, visible, mapObject) {
            if (visible && mapObject) {
                markerElement.map = mapObject;
                return;
            }

            markerElement.map = null;
        },
        /**
        * Normalizes the input data to ensure that it is consistently an array of arrays, even when a single response is returned.
        * This normalization is necessary because when multiple promises are resolved, 'data' is an array of arrays.
        * However, if only one promise is resolved, 'data' is a single array. By checking if the first element is not an array,
        * we can wrap 'data' in an additional array to maintain a consistent structure for further processing.
        *
        * @param {Array|*} data - The data to be normalized. It can be any type, but typically an array or a single element.
        * @returns {Array} - Returns the normalized data as an array of arrays. If the input is already an array of arrays, it is returned unchanged.
        */
        normalizeData: function (data) {
            return data && data.length && !Array.isArray(data[0]) ? [data] : data;
        },
        /**
        * Displays a message indicating the unavailability of certain services based on the provided message content.
        *
        * @param {jQuery} $earliestSlotDiv - A jQuery object representing the DOM element where the message should be displayed.
        * @param {string} message - The message content used to determine which unavailability message to display.
        */
        showUnavailableServiceMessage: function ($earliestSlotDiv, vehicleType) {
            if (!$earliestSlotDiv.length || !vehicleType) {
                return;
            }

            if (vehicleType === "trailertire") {
                $earliestSlotDiv[0].innerHTML = ("<div class=\"no-trailer-service\"><strong>" + SitePreferences.NO_TRAILER_SLOTS_MSG + "</strong></div>"); // eslint-disable-line
            } else {
                $earliestSlotDiv[0].innerHTML = ("<p>" + iamota_store_locator.appointmentEnhancementInfoNotAvailable + "</p>"); // eslint-disable-line
            }
        },
        /**
        * Displays a message indicating limited appointment availability.
        *
        * This function updates the inner HTML of the provided DOM element to show a
        * message when there are no available appointment slots. It uses a predefined
        * message from the SitePreferences configuration.
        *
        * @param {jQuery} $earliestSlotDiv - A jQuery object representing the DOM element
        *                                    where the no slots message will be displayed.
        *                                    The function checks if the element exists
        *                                    before attempting to update its content.
        */
        showLimitedAppointmentsMessage: function ($earliestSlotDiv) {
            if (!$earliestSlotDiv.length) {
                return;
            }

            $earliestSlotDiv[0].innerHTML = "<strong>" + SitePreferences.NO_SLOTS_MSG + "</strong>"; // eslint-disable-line
        },
        /**
        * Hides the appointment call-to-action buttons within a specified store element.
        *
        * This function targets specific elements within the provided store element
        * and hides them if they are present. It specifically looks for elements with
        * the classes "book-now" and "more-times" and hides them to prevent users from
        * booking appointments or viewing more time options.
        *
        * @param {jQuery} $storeDiv - A jQuery object representing the store element
        *                             containing the appointment call-to-action buttons.
        */
        hideAppointmentCTAs: function ($storeDiv) {
            const $bookNow = $storeDiv.find(".book-now");
            const $moreTimes = $storeDiv.find(".more-times");

            if ($bookNow.length) {
                $bookNow.hide();
            }

            if ($moreTimes.length) {
                $moreTimes.hide();
            }
        },
        /**
        * Show messages related to unavailable services or limited appointments.
        *
        * This function checks if the earliest slot element exists and then determines the appropriate
        * message to display based on the presence and content of the provided message string. If the
        * message indicates an unavailable service, it displays the unavailable service message and hides
        * the appointment call-to-action buttons. Otherwise, it shows a message indicating limited
        * appointments.
        *
        * @param {jQuery} $storeDiv - The jQuery object representing the store division element where
        *                             appointment call-to-action buttons may be hidden.
        * @param {jQuery} $earliestSlotDiv - The jQuery object representing the earliest slot division
        *                                    element where messages will be displayed.
        * @param {string} message - The message string that may contain the keyword "unavailableservice"
        *                           to indicate the type of message to display.
        */
        showMessage: function ($storeDiv, $earliestSlotDiv, message) {
            if (!$earliestSlotDiv.length) {
                return;
            }

            if (message && message.reason === "unavailableservice") {
                this.showUnavailableServiceMessage($earliestSlotDiv, message.vehicleType);
                this.hideAppointmentCTAs($storeDiv);
            } else {
                this.showLimitedAppointmentsMessage($earliestSlotDiv);
            }
        },
        /**
        * Processes a store's HTML division to show a message in the earliest available service slot div.
        *
        * This function locates the store's division element using the provided store ID and checks
        * for the presence of an element indicating the earliest available service slot. If such an
        * element is found, it shows specific message related to the unavailability of the service.
        *
        * @param {string} storeId - The unique identifier for the store, used to locate the store's HTML division.
        * @param {string} message - A message to be displayed or used when handling unavailable services.
        */
        processStoreDiv: function (storeId, message) {
            var $storeDiv = $("div.location-item[data-aplusstoreid=\"" + storeId + "\"]");
            var $earliestSlotDiv = $storeDiv.find(".earliest-available");

            if ($earliestSlotDiv.length > 0) {
                this.showMessage($storeDiv, $earliestSlotDiv, message);
            }
        },
        /**
        * Handles the scenario where no store appointment data results are received by hiding the loader and showing again the store list.
        *
        * @param {Object} dataReceived - The data received from the store appointment details page.
        */
        showStoreListPage: function () {
            var $storeBack = $(".store-back");
            var $storeDetailsLoader = $(".store-details-loader");

            if ($storeBack.length && $storeDetailsLoader.length) {
                $storeDetailsLoader.fadeOut();
                $storeBack.trigger("click");
            }
        }
    };
})();

/**
 * Browser Detect
 * Based on code provided by Quirks Mode
 * Tweaked by iamota corporation | http://iamota.com
 * @link http://www.quirksmode.org/js/detect.html
 * @version 1.1.1
 *
 * CHANGELOG
 * 2013-04-11 [1.1.1]: Renamed 'Explorer' to 'IE', added detection for 'BB10' ^Steve
 * 2013-04-08 [1.1.0]: Added support for 'BrowserDetect.isMobile' ^Steve
 * 2011-01-01 [1.0.0]: Initial Release ^Steve
 *
 * TODO
 * - Encorporate browser 'type' dectection? (https://github.com/bjankord/Categorizr)
 * - Add os, browser, and browser version css class (like modernizr)
 * - Improve version detection (e.g. browserstack.com/responsive shows errors)
 */
const BrowserDetect = {
    init: function () {
        this.browser = this.searchString(this.dataBrowser) || "unknown";
        this.version = this.searchVersion(navigator.userAgent)
            || this.searchVersion(navigator.appVersion)
            || -1;
        this.os = this.searchString(this.dataOS) || "unknown";
        this.isMobile = (this.os != "Windows" && this.os != "Mac" && this.os != "Linux");
    },
    searchString: function (data) {
        for (var i=0; i<data.length; i++) {
            var dataString = data[i].string;
            var dataProp = data[i].prop;
            this.versionSearchString = data[i].versionSearch || data[i].identity;
            if (dataString) {
                if (dataString.indexOf(data[i].subString) != -1) {
                    return data[i].identity;
                }
            } else if (dataProp) {
                return data[i].identity;
            }
        }
    },
    searchVersion: function (dataString) {
        var index = dataString.indexOf(this.versionSearchString);
        if (index == -1) {
            return;
        }

        return parseFloat(dataString.substring(index+this.versionSearchString.length+1));
    },
    dataBrowser: [
        { string: navigator.userAgent, subString: "Chrome",     identity: "Chrome" },
        { string: navigator.userAgent, subString: "CriOS",      identity: "Chrome",     versionSearch: "CriOS" }, // Chrome Mobile
        { string: navigator.userAgent, subString: "OmniWeb",    identity: "OmniWeb",    versionSearch: "OmniWeb/" },
        { string: navigator.vendor,    subString: "Apple",      identity: "Safari",     versionSearch: "Version" },
        { string: navigator.userAgent, subString: "BlackBerry", identity: "BlackBerry", versionSearch: "Version" },
        { string: navigator.userAgent, subString: "RIM",        identity: "BlackBerry", versionSearch: "Version" },
        { prop: window.opera,                                   identity: "Opera",      versionSearch: "Version" },
        { string: navigator.vendor,    subString: "iCab",       identity: "iCab" },
        { string: navigator.vendor,    subString: "KDE",        identity: "Konqueror" },
        { string: navigator.userAgent, subString: "Firefox",    identity: "Firefox" },
        { string: navigator.vendor,    subString: "Camino",     identity: "Camino" },
        { string: navigator.userAgent, subString: "Netscape",   identity: "Netscape"}, // for newer Netscapes (6+)
        { string: navigator.userAgent, subString: "MSIE",       identity: "IE",         versionSearch: "MSIE" },
        { string: navigator.userAgent, subString: "Gecko",      identity: "Mozilla",    versionSearch: "rv" },
        { string: navigator.userAgent, subString: "Dolfin",     identity: "Dolfin",     versionSearch: "Dolfin" },
        { string: navigator.userAgent, subString: "Mozilla",    identity: "Netscape",   versionSearch: "Mozilla" } // for older Netscapes (4-)

    ],
    dataOS : [
        { string: navigator.userAgent, subString: "Android",    identity: "Android" },
        { string: navigator.userAgent, subString: "iPhone",     identity: "iOS" },
        { string: navigator.userAgent, subString: "iPod",       identity: "iOS" },
        { string: navigator.userAgent, subString: "iPad",       identity: "iOS" },
        { string: navigator.userAgent, subString: "BlackBerry", identity: "BlackBerry" },
        { string: navigator.userAgent, subString: "RIM",        identity: "BlackBerry" },
        { string: navigator.userAgent, subString: "BB10",       identity: "BlackBerry" },
        { string: navigator.platform,  subString: "Linux",      identity: "Linux" },
        { string: navigator.platform,  subString: "Win",        identity: "Windows" },
        { string: navigator.platform,  subString: "Mac",        identity: "Mac" },
        { string: navigator.userAgent, subString: "Bada",       identity: "Bada" }
    ]
};

module.exports = {storeLocatorHelpers, BrowserDetect};
